// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/ui/atoms/Label.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/ui/atoms/Label.tsx");
  import.meta.hot.lastModified = "1702569433515.8843";
}
// REMIX HMR END

import * as LabelPrimitive from '@radix-ui/react-label';
import { cva } from 'class-variance-authority';
import * as React from 'react';
import { baseTextVariants } from '~/ui/atoms/Text';
import { cn } from '~/utils/cn';
const labelVariants = cva('inline-block max-w-full text-sm font-medium leading-5 peer-disabled:cursor-not-allowed peer-disabled:opacity-70', {
  variants: baseTextVariants.variants
});
const Label = React.forwardRef(_c = ({
  truncate = true,
  className,
  ...props
}, ref) => <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className, truncate && 'truncate')} {...props} />);
_c2 = Label;
Label.displayName = LabelPrimitive.Root.displayName;
export { Label };
var _c, _c2;
$RefreshReg$(_c, "Label$React.forwardRef");
$RefreshReg$(_c2, "Label");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;